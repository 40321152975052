import React from 'react';
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";

const progressData = [
    {
      id: 1,
      title: "React JS",
      percantage: 90,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 2,
      title: "React Native",
      percantage: 80,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 3,
      title: "React Three Fiber",
      percantage: 80,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
   /*  {
      id: 2,
      title: "Photoshop Design",
      percantage: 75,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    }, */
   
    {
      id: 4,
      title: "Node Js",
      percantage: 80,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 5,
      title: "SQL",
      percantage: 80,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 6,
      title: "MongoDB",
      percantage: 80,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 7,
      title: "Vue JS",
      percantage: 50,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 8,
      title: "Tailwind & Material UI",
      percantage: 70,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
  ];



const ProgressbarThree = () => {
    return (
      <div className='row'>
            {progressData.map((progress) => (
               <div className="col-lg-6 col-md-6 col-sm-6 col-12 pb--20" key={2}>
            <TrackVisibility
                once
                key={progress.id}
                className="single-progress"
            > 

              
                <Skill progress={progress} /> 
            </TrackVisibility>
            </div>
            ))} 
    </div>
    )
}

export default ProgressbarThree;

import React , {useEffect, useState} from 'react';
import emailjs from 'emailjs-com';

const Result = (response) => {
    console.log(response)
    return (
        response ?
        <p className="success-message">Grazie per avermi contattato, mi farò sentire presto.</p>
        :
        <p className="error-message">Qualcosa è andato storto, riprova più tardi .</p>
        )
}

function ContactForm({props , formStyle}) {
    const [ result,showresult ] = useState(false);
    const [responseResult,setResponseResult]=useState(false);
    const [response,setResponse]=useState(false);

    const sendEmail = (e) => {
        setResponse(true);
        e.preventDefault();
        emailjs
        .sendForm(
            'service_qv10oni', 
            'template_oq768r8', 
          /*  {name,email,phone,subject,message}, */
          e.target,
            'L8i2wBkgi80B8YT-z'
        )
        .then((result) => {
            console.log(result.text);
            setResponseResult(true);
            }, 
            (error) => {
                console.log(error.text);
                setResponseResult(false);
            }
        );
        e.target.reset();
        showresult(true);
        
    };

    setTimeout(() => {
        /* showresult(false); */
        setResponse(false)
        setResponseResult(false)

    }, 5000);

    useEffect(()=>{
        setResponse(false)
        setResponseResult(false)
        showresult(false);
    },[responseResult])

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Nome"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Indirizzo email"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="number"
                name="phone"
                placeholder="Numero di telefono"
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Oggetto"
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                placeholder="Messaggio"
                required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button disabled={response} className="btn-default btn-large">Invia</button>
            </div> 

            <div className="form-group">
                {result ? <Result response={responseResult}/> : null}
            </div> 
        </form>
    )
}
export default ContactForm;

import React,{useState} from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    title: "Analisi dei requisiti",
    description: "Present all available features in Essentials.",
  },
  {
    id: "2",
    title: "Progettazione",
    description: "All Feature available features in Essentials.",
  },
  {
    id: "3",
    title: "Sviluppo",
    description: "Popular Feature available features in Essentials.",
  },
  {
    id: "4",
    title: "Testing",
    description: "Latest Feature available features in Essentials.",
  },
  {
    id: "3",
    title: "Lancio",
    description: "Popular Feature available features in Essentials.",
  },
  {
    id: "4",
    title: "Manutenzione",
    description: "Latest Feature available features in Essentials.",
  },
];

const data =  [
    {
        id: "1",
        date: "Step-1",
        title: "Analisi dei requisiti",
        description: "La prima fase del processo lavorativo consiste nell'analizzare i requisiti dei clienti e delle parti interessate, in modo da comprendere le loro esigenze e definire gli obiettivi del progetto.",
    },
    {
        id: "2",
        date: "Step-2",
        title: "Progettazione",
        description: "Una volta compresi i requisiti, l'azienda può passare alla fase di progettazione, durante la quale viene creato un piano per sviluppare il prodotto o servizio richiesto. In questa fase si definiscono le specifiche tecniche, il design e la funzionalità del prodotto/servizio.",
    },
    {
        id: "3",
        date: "Step-3",
        title: "Sviluppo",
        description: "la fase di sviluppo è quella in cui il prodotto o servizio viene effettivamente creato. In questa fase gli sviluppatori utilizzano i linguaggi di programmazione e le tecnologie necessarie per creare il prodotto/servizio, seguendo le specifiche definite nella fase di progettazione.",
    },
    {
        id: "4",
        date: "Step-4",
        title: "Testing",
        description: "Dopo che il prodotto/servizio è stato creato, si procede con una fase di testing, durante la quale si verificano eventuali problemi e si testano le funzionalità del prodotto/servizio. In questa fase si eseguono test di unità, test di integrazione, test di sistema e test di accettazione.",
    },

    {
        id: "5",
        date: "Step-5",
        title: "Lancio",
        description: " Una volta superata la fase di testing, il prodotto/servizio viene lanciato sul mercato. Questa fase può includere l'implementazione di una strategia di marketing e la definizione di un piano di lancio.",
    },

    {
        id: "6",
        date: "Step-6",
        title: "Supporto e manutenzione",
        description: " dopo il lancio del prodotto/servizio, l'azienda può offrire servizi di supporto e manutenzione per garantire il corretto funzionamento del prodotto/servizio e rispondere alle richieste dei clienti.",
    },
]
const TimelineTwo = ({ classVar }) => {
    const [indexSelected,setIndexSelected]=useState(0);
    return (
    <>
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-2 col-md-2 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6 className="title" style={{cursor:'pointer'}} onClick={()=>setIndexSelected(index)}>{data.title}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner" style={{cursor:'pointer',backgroundColor:index === indexSelected ? '#fff' : '#264b87'}} onClick={()=>setIndexSelected(index)}></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
{/*                 <p className="description">{data.description}</p> */}
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
      
    </div>
    <div className="single-timelineDesktop mt--100 " key={1}>
    <div className="row">
                            <div className="col-lg-12">
    <div className="single-content">
    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                        <div className="inner lg-12">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-8 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data[indexSelected].date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data[indexSelected].title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data[indexSelected].description}</p>
                                        </ScrollAnimation>
{/* 
                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div> */}
                                      {/*   <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/contact"><span>Get Started Now</span></a>
                                            </ScrollAnimation>
                                        </div> */}
                                    </div>
                                </div>
                              {/*   <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

    </div>
    </div>
    </div>
    </>
  );
};
export default TimelineTwo;

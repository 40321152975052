import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 45.11151333253681,
      lng: 7.507306457065391
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
         bootstrapURLKeys={{ key: 'AIzaSyDQVk9JkLfTVACKD7YCFhfJLEF5X5xzmq8' }}
          defaultCenter={this.props.center}
          defaultZoom={15}
        >
          <AnyReactComponent
            lat={45.11151333253681}
            lng={7.507306457065391}
            text="DevLane"
          
          />
        </GoogleMapReact>
    
      </div>
    );
  }
}

export default GoogleMapStyle;
import React from "react";
import { Link } from "react-router-dom";

const PortfolioItem = ({ portfolio }) => {
  console.log(portfolio);
  return (
    <div className="rwt-card">
      <div className="inner">
        <div className="thumbnail">
          <figure className="card-image">
            <a href={portfolio.href || ""} target="_blank" rel="noreferrer">
              <img
                className="img-fluid"
                src={portfolio.portfolioImage}
                alt="Portfolio-01"
              />
            </a>
          </figure>
          {/*   <Link to={process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`} className="rwt-overlay"></Link> */}
        </div>
        <div className="content">
          <h5 className="title mb--10">
            <a href={portfolio.href || ""} target="_blank" rel="noreferrer">
              {portfolio.title}
            </a>
          </h5>
          {portfolio?.category?.length > 1 ? (
            portfolio?.category?.map((el,index) => {
              return <span className="subtitle b2 text-capitalize">{index!==0 ? "|" : ""} {el} </span>;
            })
          ) : (
            <span className="subtitle b2 text-capitalize">
              {portfolio.category}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default PortfolioItem;

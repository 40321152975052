import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import VideoItem from "../elements/video/VideoItem";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import CalltoActionFive from "../elements/calltoaction/CalltoActionSeven";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import * as rdd from "react-device-detect";
import ProgressbarThree from "../elements/progressbar/ProgressbarThree";
import SlipThree from "../elements/split/SlipThree";

var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const HomeDefault = () => {
  return (
    <>
      <SEO title="Business Consulting" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/test-slides.png)`,
          }}
        >
          <div className="container" id="top">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  {/*                                     <span className="subtitle">AZIEDA SVILUPPO SOFTWARE</span>
                   */}{" "}
                  <h1 className="title display-one">
                    DevLane <span>Consulting</span>
                  </h1>
                  <p className="description">
                    Sono un freelance che si occupa di sviluppo software specializzato in
                    soluzioni su misura per le esigenze dei clienti.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/contatti"
                    >
                      Contattami
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <div className="service-area rn-section-gapTop pt--100 pb--100" >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                   Sono un freelance con 8 anni di esperienza nello sviluppo software.
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                Il mio obiettivo è quello di aiutare i clienti a trarre il massimo vantaggio dalla tecnologia, consentendo loro di migliorare l'efficienza aziendale e raggiungere i loro obiettivi commerciali utilizzando le ultime tecnologie in modo innovativo.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100" id="service">
          <SectionTitle
            textAlign="text-center"
            radiusRounded=""
            subtitle="Servizi"
            title="I servizi offerti"
            description="Di seguito l'elenco dei servizi che posso offrirti"
          />
          <div className="container">
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
      
        </div>
        {/* End Service Area  */}

        <Separator />


        <div className="rwt-portfolio-area rn-section-gap" id="competenze">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Competenze"
                  title="Le mie competenze"
                  description=""
                />
              </div>
            </div>
            <ProgressbarThree Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>

        {/* Start Portfolio Area  */}

        {/* End Portfolio Area  */}

        <Separator />

        <div className="rwt-portfolio-area rn-section-gap pb--50" id="metodologia">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="metodologia di lavoro"
                  title="Il nostro metodo di lavoro"
                  description="Clicca sui titoli per scoprire come lavoriamo"
                />
              </div>
            </div>
            {!rdd.isMobile ? (
          <div className="rwt-timeline-area rn-section-gapBottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <TimelineTwo classVar="no-gradient" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="rwt-timeline-area rn-section-gap">
            <div className="container">
              <div className="row">
               
              </div>
              <div className="row mt_dec--20">
                <div className="col-lg-12">
                  <TimelineOne />
                </div>
              </div>
            </div>
          </div>
        )}
          </div>
        </div>


        {/* End Timeline Area  */}

        {/* Start Video Area  */}
        {/*  <div className="rwt-video-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            {PopupData.map((item) => (
                                <div className="col-lg-12" key={item.id}>
                                    <VideoItem galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Video Area  */}

        <Separator />
        <div className="rwt-portfolio-area rn-section-gap" id="portfolio">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="portfolio"
                  title="Gli ultimi progetti"
                  description="Di seguito l'elenco degli ultimi progetti su cui abbiamo lavorato!"
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>

        <Separator />

        {/* Start Blog Area  */}

        <Separator />
        <CalltoActionFive />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;

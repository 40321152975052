import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Sviluppo di applicazioni web',
        description: 'Creazione di software accessibile tramite browser, offrendo funzionalità come gestione dati, utenti e pagamenti online.'
    },
    {
        icon: <FiCast />,
        title: 'Sviluppo di applicazioni mobili',
        description: 'Creazione di applicazione cross platform per dispositivi mobili, offrendo funzionalità come interazione con database, elaborazione dati e gestione utenti.'
    },
    {
        icon: <FiMap />,
        title: 'Sviluppo di backend e API',
        description: 'Creazione di logica di gestione dati e servizi web per applicazioni, fornendo accesso ad api rest per la gestione dell\'elaborazione dei dati.'
    },
    {
        icon: <FiActivity />,
        title: 'Sviluppo di applicazioni nel web 3.0.',
        description: 'Creazione di applicazioni basate su tecnologie avanzate come IA, blockchain, realtà aumentata, offrendo esperienze utente avanzate.'
    },
    {
        icon: <FiCast />,
        title: 'Sviluppo di POC e MVP',
        description: 'Creazione di prototipi e versioni minime funzionanti di applicazione per testare nuove idee di prodotto.'
    },
    {
        icon: <FiMap />,
        title: 'Sviluppo di siti web',
        description: 'Creazione di siti statici (Next Js) o dinamici attraverso l\'utilizzo di CMS come Wordpress, offrendo funzionalità come gestione contenuti e pagamenti online.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;